import { DeactivateChapter, ReactivateChapter, DeleteChapter, UndeleteChapter } from "./chapterViewOperations.gql";
import * as BE from "@/utils/api/backendException.js";
import { hasuraClient } from "@/utils/graphql/apollo.js";
// import { Logger, DEBUG } from "@/utils/plugins/logging";
import { call } from "@/utils/api/base.js";

export async function deactivateChapter(chapterId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "Chapter does not exist."],
    [BE.NOT_SUPPORTED, "Must deactivate all Series within Chapter before Chapter can be deactivated."]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error deactivating chapter."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: DeactivateChapter,
        variables: {
          chapterId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function reactivateChapter(chapterId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "Chapter does not exist."]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error reactivating chapter."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: ReactivateChapter,
        variables: {
          chapterId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function deleteChapter(chapterId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "Chapter does not exist."]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error deleting chapter."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: DeleteChapter,
        variables: {
          chapterId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function undeleteChapter(chapterId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "Chapter does not exist."]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error undeleting chapter."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: UndeleteChapter,
        variables: {
          chapterId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}
