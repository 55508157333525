<script>
import { SuawMainContent, SuawSection, SuawInputGroup, SuawHeading } from "@/components";
import ChapterViewBasic from "./components/ChapterViewBasic.vue";
import ChapterViewSeries from "./components/ChapterViewSeries.vue";

export default {
  name: "ChapterView",
  components: {
    SuawMainContent,
    SuawSection,
    SuawInputGroup,
    SuawHeading,
    ChapterViewBasic,
    ChapterViewSeries
  },
  computed: {
    chapterId() {
      return this.$route.params.chapterId;
    }
  }
};
</script>

<template>
  <SuawMainContent>
    <SuawSection section-size="medium">
      <SuawInputGroup direction="column">
        <SuawHeading content="Chapter Admin" level="3" />
        <ChapterViewBasic :chapter-id="chapterId" />
        <ChapterViewSeries :chapter-id="chapterId" />
      </SuawInputGroup>
    </SuawSection>
  </SuawMainContent>
</template>

<style lang="css" scoped></style>
